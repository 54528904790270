import React from "react"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import Notification from "../components/landingPages/pageContents/Notification"

const NotificationPage = ({ data }) => (
  <Layout>
    <SEO title="Thông báo" />
    <Notification />
  </Layout>
)

export default NotificationPage
